body {
  margin: 0;

  @media (--gte-tablet) {
    background-image: svg-load('speech_bubble.svg'), svg-load('speech_bubble2.svg');
    background-repeat: no-repeat, no-repeat;
    background-position: -100px 700px, right 1500px;
    background-size: 680px;
  }

  @media (--gte-desktop) {
    background-position: -100px 300px, right 1200px;
    background-size: 1000px;
  }

  @media (--gte-largedesktop) {
    background-position: -100px 300px, right 1100px;
  }
}
