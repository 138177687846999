.newsletter {
  margin: 30px 0 100px;
  background-color: var(--blue4);

  .container {
    padding: 60px 10px;
    position: relative;

    @media (--gt-phone) {
      padding: 60px 30px;
    }

    @media (--gte-largephone) {
      padding: 60px;
    }

    @media (--gte-desktop) {
      padding: 60px 10px;
    }

    .row {
      align-items: flex-start;
    }

    .column {
      &:first-child {
        color: var(--black);
        font-size: 20px;
      }

      &:last-child {
        padding-top: 20px;

        @media (--gte-desktop) {
          padding-left: 40px;
          padding-top: 0;
        }
      }
    }
  }

  input[type='email'] {
    @mixin input;
  }

  input[type='submit'] {
    @mixin button;
  }

  .input {
    margin-bottom: 10px;
  }

  .input__hint {
    color: var(--purple6);
  }
}
