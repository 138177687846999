@import '~assets/theme/views/pages/home/_about.css';
@import '~assets/theme/views/pages/home/_body.css';
@import '~assets/theme/views/pages/home/_hero.css';
@import '~assets/theme/views/pages/home/_navigation.css';
@import '~assets/theme/views/pages/home/_newsletter.css';
@import '~assets/theme/views/pages/home/_rocket.css';

.hero {
  background: rgb(26, 20, 80);
  background: linear-gradient(
    180deg,
    rgba(26, 20, 80, 1) 0%,
    rgba(48, 34, 56, 1) 35%,
    rgba(132, 99, 185, 1) 100%
  );
}

.home-rated {
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.4);
  border-bottom: 1px solid var(--gray3);

  div {
    display: flex;
    justify-content: center;
    color: var(--purple9);
    font-size: 1.25rem;
    letter-spacing: 0.0625rem;
    padding: 23px 10px 20px;
    text-align: center;
    text-transform: uppercase;
    border-top: 1px solid var(--darker-orange);
  }

  i {
    margin: 0 10px;
    color: var(--darker-orange);
  }
}

.logos {
  padding: 16px 0px;
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.4);

  > div {
    @mixin container;

    @media (--gte-desktop) {
      width: 54%;
    }
  }

  p {
    text-align: center;
    color: var(--black);
    margin: 0 0 8px;
    font-size: 14px;
    letter-spacing: 0.4px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;

    @media (--gte-tablet) {
      flex-direction: row;
      align-items: center;
    }
  }

  li {
    display: inline-block;
    margin-bottom: 4px;

    @media (--gte-tablet) {
      margin-bottom: 0;
    }
  }
}

.about {
  @media (--lt-tablet) {
    margin: 0 0 80px;
  }
}

.newsletter {
  margin: 100px 0 -51px;

  .container {
    padding: 80px 10px 80px;
  }
}

.guarantee {
  padding: 0 0 90px;

  .container {
    display: flex;
    align-items: center;
    background-color: var(--purple3);
    border-radius: 20px;
    box-shadow: var(--floating-shadow);
    color: var(--white);
    padding: 44px 44px 44px 72px;

    @media (--lt-tablet) {
      flex-direction: column;
      padding: 40px;
    }
  }

  h2 {
    margin: 0 0 10px 50px;
    font-size: 32px;
    line-height: 1.2;
    white-space: nowrap;

    @media (--lt-tablet) {
      margin-left: 0;
      margin-top: 20px;
      text-align: center;
    }
  }

  p {
    font-size: 18px;
    margin: 0 0 0 50px;
    max-width: 800px;

    @media (--lt-tablet) {
      margin-left: 0;
      text-align: center;
    }
  }
}
