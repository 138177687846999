.about {
  margin: 80px 0;
  padding: 10px 0 60px;
  background-color: var(--purple8);
  background-size: 780px;
  background-position: -100px 0;
  background-repeat: no-repeat;
  color: var(--white);
  position: relative;

  @media (--gt-phone) {
    padding: 10px 10px 60px;
  }

  @media (--gte-tablet) {
    background-size: contain;
    background-position: 0 0;
  }

  @media (--gt-desktop) {
    background-size: cover;
  }

  .container {
    @mixin container;
    position: relative;

    @media (--gte-tablet) and (--lte-tablet) {
      padding-left: 270px;
    }

    @media (--gt-tablet) {
      padding-left: 350px;
    }
  }

  h2 {
    color: var(--white);
    margin: 215px 0 0;

    @media (--gte-largephone) {
      margin: 0;
      padding: 90px 0 0 176px;
      height: 200px;
    }

    @media (--gte-tablet) {
      height: auto;
      padding: 50px 0 0;
    }
  }

  blockquote {
    margin: 20px 0;
    opacity: 0.8;
    font-size: 17px;
    line-height: 1.4;

    p {
      margin-bottom: 20px;
    }

    span {
      font: 22px cursive;
      line-height: 0;
    }

    a {
      color: var(--white);
      text-decoration: underline;
    }
  }

  p {
    margin: 0;

    strong {
      font-size: 20px;
    }

    small {
      opacity: 0.8;
      font-size: 14px;
    }
  }

  ul {
    list-style: none;
    margin: 30px 0 10px;
    padding: 0;

    li {
      display: inline-block;
      margin-right: 15px;

      @media (--gte-tablet) {
        margin-right: 30px;
      }

      &:last-child {
        margin-right: 0;
      }

      img {
        @media (--lt-tablet) {
          width: 70px;
          height: auto;
        }
      }
    }
  }

  .button {
    @mixin button-transparent;
    margin-top: 40px;
    font-size: 15px;
  }
}

.about__fade {
  background-image: linear-gradient(rgba(50, 42, 52, 0) 20%, var(--purple8) 60%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  @media (--gte-desktop) {
    display: none;
  }
}
