.nav_scrolled {
  background: transparent;
  box-shadow: none;
  z-index: 98;
  position: absolute;
  transition: top 400ms ease 0s;

  .nav__logo {
    background: transparent url('/images/logo_white.png') no-repeat;
    background-size: contain;
    background-position: 0 10px;
    display: block;

    img {
      visibility: hidden;
    }
  }

  @media (--lt-tablet) {
    button,
    a {
      color: var(--white);
    }
  }

  .nav__link {
    color: var(--white);

    &::before {
      background-color: var(--purple2);
    }
  }

  .nav__link_login {
    color: var(--white);
    border-color: var(--white);

    &:hover {
      border-color: var(--purple2);
      background-color: var(--purple2);
    }
  }

  .nav__link_icon {
    color: var(--white);

    &:hover {
      border-color: var(--purple2);
      background-color: var(--purple2);
    }
  }
}

nav:not(.nav_scrolled) {
  position: fixed;
  z-index: 97;
}
