.rocket {
  margin: 0 0 80px;
  text-align: center;
  color: var(--purple6);

  @media (--gte-desktop) {
    margin: 40px 0 120px;
  }

  h2 {
    font-size: 36px;
    margin: 20px 0 6px;
  }

  h3 {
    font-size: 20px;
    font-weight: 300;
    margin: 0 0 30px;
  }
}
