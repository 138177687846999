.hero {
  background: var(--purple8) url('/images/home_hero.jpg') no-repeat center;
  width: 100%;
  display: flex;
  position: relative;
  background-size: cover;
  color: var(--white);
  z-index: 97;

  h1,
  h2,
  h3 {
    font-weight: 300;
    color: var(--white);
    text-shadow: 0 0 4px rgba(56, 41, 60, 0.4);
  }

  h1 {
    margin-top: 100px;
    font-weight: 500;
    line-height: 1.2;
    font-size: 36px;

    @media (--gt-phone) {
      font-size: 38px;
    }

    @media (--gte-tablet) {
      font-size: 44px;
      margin-top: 140px;
    }

    @media (--gte-desktop) {
      font-size: 62px;
    }
  }

  h2 {
    font-size: 20px;
    max-width: 700px;
    margin: 20px auto;
    line-height: 1.3;

    @media (--gte-largephone) {
      font-size: 22px;
    }

    @media (--gte-tablet) {
      font-size: 24px;
    }
  }

  h3 {
    font-size: 18px;
    margin-top: 50px;
    opacity: 0.8;

    @media (--gte-largephone) {
      margin-top: 30px;
    }

    @media (--gte-desktop) {
      margin-top: 0;
    }
  }
}

.hero-container {
  @mixin container;
  position: relative;
  text-align: center;
  margin-bottom: 60px;

  @media (--gte-tablet) {
    margin-bottom: 100px;
  }
}

.hero-cta {
  @mixin button;
  font-size: 22px;
  padding: 14px 20px 12px 20px;
  font-weight: 300;
  margin-top: 15px;
  box-shadow: var(--floating-shadow);

  i {
    margin-right: 16px;
  }
}

.hero-nav_fade {
  height: 276px;
  background-image: linear-gradient(-180deg, rgba(50, 42, 52, 0.5) 0%, rgba(50, 42, 52, 0) 76%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.hero-bg_fade {
  background-color: var(--purple8);
  opacity: 0.68;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
